import request from "@/service/";
import * as url from "url";


export const addEquipments = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments`,
        data
    );
}

export const updateEquipments = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/equipments/${data.id}`,
        data
    );
}


export const getEquipmentList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments`,
        {
            params: params
        }
    );
}

export const getEquipmentById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments/${id}`,
    );
}


export const getEquipmentsByStorageId = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments/storages/${id}`,
    );
}
export const getEquipmentsByStorages = (id) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/listByStorages`,
		id
    );
}


export const printEquipmentsTags = (ids) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments/qrCodes/print?id=${ids}`,
        {
            responseType: 'blob'
        }
    );
}

export const deleteOneEquipmentById = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/equipments/${id}/delete`,
    );
}

export const exportEquipmentExcel = (params) => {
 return request.get(
     `${window.VUE_APP_URL}/api/equipments/export`,
     {
         params:params,
         responseType:'blob'
     },
 )
}

export const SapAccept = (params) => {
    return request.post(
        `${window.VUE_APP_URL}/api/Equipments/SapAccept`,
         params
    );
}


export const GetMainProfileList = (params) => {
 return request.get(
     `${window.VUE_APP_URL}/api/MainProfile/GetMainProfileList`,{
            params: params
        })
}  


export const MainProfileManager = (params) => {
    return request.post(
        `${window.VUE_APP_URL}/api/MainProfile/MainProfileManager`,
         params
    );
}


export const editMainProfileManager = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/MainProfile/${data.id}/MainProfileManager`,
        data
    );
}

export const uploadfilesapi = data => {
    return `${window.VUE_APP_NEW_URL}/api/equipments/${data}/uploadfiles`;
}

export const InvalidAttachment = (params) => {
    return request.post(
        `${window.VUE_APP_URL}/api/Utils/InvalidAttachment`,
         params
    );
}
