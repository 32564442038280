import request from "@/service/";


export const addStorage = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/refs/storages`,
        data
    );
}

export const updateStorage = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/refs/storages/${data.id}`,
        data
    );
}


export const getStorageList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/storages`,
        {
            params: params
        }
    );
}

export const getStorageById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/storages/${id}`,
    );
}

export const deleteOneStorage = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/refs/storages/${id}/delete`,
    );
}

export const CostCenter = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/refs/CostCenter`,
    );
}
